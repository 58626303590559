import { useContext } from 'react';
import { LinkContext } from '../providers/link-provider';

export const useLink = () => {
  const linkContext = useContext(LinkContext);

  if (!linkContext.framework) {
    throw new Error('No link framework found. Please ensure that useLink is used within a LinkProvider.');
  }

  return linkContext;
};

import { useLink } from '@storefront/ui-components/hooks/useLink';
import { Pagination, type PaginationConfig } from '@storefront/ui-components/shared/pagination';
import { isBrowser } from '@storefront/util/browser';
import type { FC } from 'react';

function getPaginationLink({
  context,
  page,
  section,
  difference = 0,
  prefix = '',
}: {
  prefix?: string;
  context: string;
  page: number;
  section?: string;
  difference?: number;
}) {
  const windowSearch = isBrowser() ? window.location.search : '';
  const contextSplit = isBrowser() ? context.split('?')[1] || `${windowSearch}` : context.split('?')[1];
  const contextSearchParams = new URLSearchParams(contextSplit);
  const newPage = page + difference;

  if (newPage > 0) contextSearchParams.set(`${prefix}page`, newPage.toString());
  return `/${context.split('?')[0].replace('?', '')}?${contextSearchParams.toString()}${section ? `#${section}` : ''}`;
}

export const PaginationWithContext: FC<{
  context: string;
  section?: string;
  paginationConfig: PaginationConfig;
}> = ({ context, section, ...props }) => {
  const linkContext = useLink();

  if (!props.paginationConfig) return null;
  const prefix = props.paginationConfig.prefix;

  return (
    <Pagination
      {...props}
      linkContext={linkContext}
      getPreviousProps={({ currentPage }) => ({
        href: getPaginationLink({
          context,
          prefix,
          page: currentPage,
          difference: -1,
          section,
        }),
      })}
      getPaginationItemProps={({ page }) => ({
        href: getPaginationLink({ context, prefix, page, section }),
      })}
      getNextProps={({ currentPage }) => ({
        href: getPaginationLink({
          context,
          prefix,
          page: currentPage,
          difference: 1,
          section,
        }),
      })}
    />
  );
};
